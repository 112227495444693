.feature {
  display: flex;

  --_max-width: 1600px;

  max-width: var(--_max-width);
  margin: auto;
}

.feature__content {
  display: flex;
  flex-direction: column;
  gap: var(--space-between-m);
  padding: var(--space-between-m);
}

.feature__media {
  flex-grow: 1;
}

.feature--layout-sm-vert-ab {
  flex-direction: column;
}

.feature--layout-sm-vert-ba {
  flex-direction: column-reverse;
}

@media (width >= 48em) {
  .feature {
    display: flex;
    gap: 0;
  }

  .feature__content {
    gap: var(--space-between-l);
    padding: var(--space-between-l);
  }

  .feature__media {
    gap: var(--space-between-l);
  }

  .feature--layout-md-vert-ab {
    flex-direction: column;
  }

  .feature--layout-md-vert-ba {
    flex-direction: column-reverse;
  }

  .feature--layout-md-horiz-ab {
    flex-direction: row;
  }

  .feature--layout-md-horiz-ba {
    flex-direction: row-reverse;
  }

  .feature--layout-md-horiz-ab .feature__content,
  .feature--layout-md-horiz-ba .feature__content {
    min-width: 50%;
    max-width: 50%;
  }

  .feature--align-md-start {
    place-items: start;
  }

  .feature--align-md-center {
    place-items: center;
  }

  .feature--align-md-end {
    place-items: end;
  }
}

@media (width >= 64em) {
  .feature--layout-lg-vert-ab {
    flex-direction: column;
  }

  .feature--layout-lg-vert-ba {
    flex-direction: column-reverse;
  }

  .feature--layout-lg-horiz-ab {
    flex-direction: row;
  }

  .feature--layout-lg-horiz-ba {
    flex-direction: row-reverse;
  }

  .feature--layout-lg-horiz-ab .feature__content,
  .feature--layout-lg-horiz-ba .feature__content {
    min-width: 50%;
    max-width: 50%;
  }

  .feature--align-lg-start {
    place-items: start;
  }

  .feature--align-lg-center {
    place-items: center;
  }

  .feature--align-lg-end {
    place-items: end;
  }
}
