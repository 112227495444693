@use '@virginmediao2/momentum-css/react/themes/virgin-media';

.host {
  font-family: VMMomentum, Arial, sans-serif;

  --color-footer: var(--color-gradient-gradient-alpha);
}

@font-face {
  font-family: VMMomentum;
  font-style: normal;
  font-weight: 300 900;
  font-stretch: 100% 100%;
  src: url('https://a.storyblok.com/f/253875/x/abefcd6861/llcircular-vf-ascii-only.woff2')
    format('woff2-variations');
  font-display: swap;
}
